export const BREAK_POINTS = {
  SMALL_DEVICE: "576px",
  MEDIUM_DEVICE: "768px",
}
export const SPACING = {
  EXTRA_SMALL: "4px",
  SMALL: "8px",
  MEDIUM: "14px",
  LARGE: "22px",
  EXTRA_LARGE: "36px",
  EXTRA_EXTRA_LARGE: "4rem",
  DEFAULT: "14px",
  DEFAULT_PADDING: "15px",
  SECTION_PADDING: "40px",
}

export const SIZE = {
  MAX_WIDTH: "1140px",
  HEADER_HIGHT: "125px",
}
export const FONT_FAMILY = "Mulish"

export const FONT_SIZES = {
  CALL_TO_ACTION_TERTIARY: "18px",
  HEADER_MAIN: "60px",
  HEADER_MAIN_MOBILE: "30px",
  PARAGRAPH_TITLE: "20px",
  REVIEWS_TITLE: "40px",
  TITLE_PRIMARY: "40px",
  TITLE_SECONDARY: "32px",
  TITLE_SECONDARY_MOBILE: "24px",
  TITLE_TERTIARY: "22px",
  TITLE_TERTIARY_MOBILE: "16px",
  DESCRIPTION_MAIN: "22px",
  QUOTED_TESTIMONIAL: "36px",
  DESCRIPTION_MAIN_MOBILE: "16px",
  PARAGRAPH_SECONDARY: "16px",
  TINY_TEXT: "12px",
}

export const COLORS = {
  INACTIVE_DOTS: "rgba(0, 0, 0, 0.4)",
  PRIMARY: "#fcb335",
  PRIMARY_TEXT: "#000000",
  PRIMARY_BUTTON_TEXT: "#ffffff",
  PRIMARY_BUTTON_HOVER_BACKGROUND: "#ffffff",
  TITLE_COLOR_INVERSE: "#ffffff",
  PARAGRAPH_TEXT: "#4F4F4F",
  PARAGRAPH_TEXT_INVERSE: "rgba(255, 255, 255, 0.8)",
  BACKGROUND: "#000000",
  BACKGROUND_SECONDARY: "rgba(0, 0, 0, 0.02)",
  BACKGROUND_TERTIARY: "#333333",
  SECTION_TEXT: "#ffffff",
  SECONDARY_TEXT: "#a3a3aa",
  TERTIARY_TEXT: "#ccc",
  MESSAGE_TEXT: "#4F4F4F",
  DIVIDER: "#00000026",
  BUTTON_PRIMARY_HOVER: "#ffffff",
}
